import React, { useEffect, useRef, useState } from "react";
import link from "../assets/images/link-01.svg";
import { data } from "../data/config";
import { FILE_UPLOAD, URL,} from "../data/constants";
import styles from "../styles/QrForm.module.css";
import CustomInput from "./CustomInput";

const QrForm = ({qrCode}) => {
  const [url, setUrl] = useState(data.data);
  const [uploadedFile, setUploadedFile] = useState(data.image);
  const ref = useRef();

  useEffect(() => {
    // qrCode.update: updates QR code based on some options 
    qrCode.update({
      data:url,
      image:uploadedFile,
    })
  },[url,uploadedFile,qrCode])

/**
 * Handles change events input element for specific cases.
 * @param {e} event - changeEvent
 * @return {void}
 * 
*/
  const handleChange = (e) => {
    const {name,value} = e.target
    
    switch(name){

      //sets url to generate QR code
      case URL:
        console.log(name);
        setUrl(value);
        data.data = url;
        break;

      //uploads file sets it to the center of QR code
      case FILE_UPLOAD:
        const [file] = e.target.files 
        if(checkFileExtension(file)){
          let reader = new FileReader();
          reader.onload = function() {
            setUploadedFile(reader.result);
          }
          reader.readAsDataURL(file);
          data.image = uploadedFile;

        }
        break;
    
      default: 
    }
  }

/**
 * Handles removal of uploaded file.
 *  @return {void}
*/
  const handleRemove=()=>{
    if(uploadedFile){
      ref.current.value = ""
      setUploadedFile("")
    }
  }
/**
 * Checks the specified file extension to upload.
 * @param {file} fileObject - input file
 * @return {true}
*/
  const checkFileExtension = (file) => {
    const fileExtension = file.name.split(".").at(-1).toLowerCase();
    const allowedFileTypes = ["jpg","png","svg"];
    if (!allowedFileTypes.includes(fileExtension)) {
      window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
      return false;
    }
    return true
  }

  return (
    <div className={styles.form__container} data-testid="form">
      {/* qr form title container */}
      <div className={styles.title_container}>
        <p className={styles.title}>QR Code Options</p>
        <p className={styles.sub_title}>
          Customise your QR Code using the options below
        </p>
      </div>
      <div className={styles.url_container}>
        <p className={styles.url_title}>QR Code Link</p>
        <div className={styles.url__box}>
           {/* Https Link input*/}
          <input 
            className={styles.url__input} 
            onChange={handleChange} 
            accept="image/png, image/jpeg image/svg"
            name="url" 
            value={url} 
            placeholder="https://...." 
            type="url" 
            data-testid="url_input" />
          <img  className={styles.url_icon} src={link} alt={link} />
        </div>
      </div>
      <div className={styles.upload_btn_container}>
        <p className={styles.upload_title}>Upload a Logo <span>(Optional)</span></p>
        <p className={styles.upload_sub_title}>
          The logo with be positioned in the centre of the QR Code
        </p>
        {/* file upload container */}
        <div className={styles.upload_input_container}>
          <input onChange={handleChange} name='file-upload' type="file" data-testid="fileupload" ref={ref}/>
          <span className={styles.remove_btn} onClick={handleRemove}>{uploadedFile ? "Remove":null}</span>
        </div>
      </div>
      <div>
        {/* custom input fields*/}
        <CustomInput qrCode={qrCode}/>
      </div>
    </div>
  );
};

export default QrForm;
