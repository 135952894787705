/**
 * Downloads Qr code
 * @param {fileExtension} file - File format
 * @param {qrCode} object - qrcode styling object
 * @return {void}
 * 
*/

export const onDownloadClick = (fileExtension,qrCode) => {
  if (!qrCode) return;
  qrCode.download({
    extension:fileExtension
  });
}