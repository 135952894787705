 const URL = "url";
 const WIDTH = "width";
 const HEIGHT = "height";
 const MARGIN = "margin";
 const FILE_UPLOAD = "file-upload"
 const DOTS_STYLE = "dots-style";
 const CORNER_SQUARE = "corner-square";
 const CORNER_DOTS = "corner-dots";
 const DOTS_COLOR = "dots-color";
 const CORNERSQUARE_COLOR = "cornersquare-color";
 const CORNERDOTS_COLOR = "cornerdots-color";
 const BACKGROUND_COLOR = "background-color"

export {
  URL,
  WIDTH,
  HEIGHT,
  MARGIN,
  FILE_UPLOAD,
  DOTS_STYLE,
  CORNER_SQUARE,
  CORNER_DOTS,
  DOTS_COLOR,
  CORNERSQUARE_COLOR,
  CORNERDOTS_COLOR,
  BACKGROUND_COLOR
}


