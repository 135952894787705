const data = {
  width: 300,
  height: 300,
  margin:0,
  data: "https://www.avmsolutionsuk.com/",
  image:
    "",
  dotsOptions: {
    color: "#000000",
    type: "square",
  },
  cornersSquareOptions: {
    color: '#000000',
    type: "square"   
  },
  cornersDotOptions:{
    color: '#000000',
    type: "square"  
  },  
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
   
    margin: 0
  },
  backgroundOptions: {
    color: '#FFFFFF',
  },
}

export {data}