import React, { useEffect, useState } from "react";
import styles from "../styles/CustomInput.module.css";
import downicon from "../assets/images/Icon.svg";
import heighticon from "../assets/images/spacing-height-01.svg";
import widthicon from "../assets/images/spacing-width-01.svg";
import margin from "../assets/images/maximize-02.svg";
import { data } from "../data/config";
import { BACKGROUND_COLOR, CORNERDOTS_COLOR, CORNERSQUARE_COLOR, CORNER_DOTS, CORNER_SQUARE, DOTS_COLOR, DOTS_STYLE,HEIGHT, MARGIN,WIDTH } from '../data/constants';

const CustomInput = ({qrCode}) => {
  const [status, setStatus] = useState(false);
  const [qrWidth, setQrWidth] = useState(data.width);
  const [qrHeight, setQrHeight] = useState(data.height);
  const [qrMargin, setQrMargin] = useState(data.margin);
  const [dotsStyle, setDotsStyle] = useState(data.dotsOptions.type);
  const [cornerSquareOption, setcornerSquareOption] = useState(data.cornersSquareOptions.type)
  const [cornerDotOption, setcornerDotOption] = useState(data.cornersDotOptions.type)
  const [dotsColor, setDotsColor] = useState(data.dotsOptions.color)
  const [cornerSquareColor, setCornerSquareColor] = useState(data.cornersSquareOptions.color)
  const [cornerDotColor, setCornerDotColor] = useState(data.cornersDotOptions.color)
  const [background, setBackground] = useState(data.backgroundOptions.color) 

  useEffect(() => {
    qrCode.update({
      width:qrWidth,
      height:qrHeight,
      margin: qrMargin,
  
      dotsOptions:{
        color:dotsColor,
        type:dotsStyle
      },
      cornersSquareOptions:{
        color: cornerSquareColor,
        type:cornerSquareOption
      },
      cornersDotOptions:{
        color:cornerDotColor,
        type:cornerDotOption
      },
      backgroundOptions:{
        color:background
      } 
    })
  },[qrCode,qrWidth,qrHeight,qrMargin,dotsStyle,cornerSquareOption,cornerDotOption,dotsColor,cornerSquareColor,cornerDotColor,background])

/**
 * Handles change events in input element for specific cases.
 * @param {e} event - changeEvent
 * @return {void}
 * 
*/
  const handleChange = (e) => {
    const {name,value} = e.target
    
    switch(name){
      //sets QR Code width
      case WIDTH:
        let widthVal = parseInt(value)
        setQrWidth(widthVal);
        data.width = qrWidth;
        break;

      // sets QR Code height 
      case HEIGHT:
        let heightVal = parseInt(value)
        setQrHeight(heightVal);
        data.height = qrHeight;
        break;

      // sets QR Code margin   
      case MARGIN:
        setQrMargin(value);
        data.margin = qrMargin;
        break;

      // sets QR Code dotsStyle   
      case DOTS_STYLE:
        setDotsStyle(value);
        data.dotsOptions.type = dotsStyle;
        break;

      // sets QR Code cornerSquareStyle  
      case CORNER_SQUARE:  
        setcornerSquareOption(value);
        data.cornersSquareOptions.type = cornerSquareOption;
        break;

      // sets QR Code cornerSquareDotsStyle   
      case CORNER_DOTS:
        setcornerDotOption(value);
        data.cornersDotOptions.type = cornerDotOption;
        break;

      // sets QR Code dotsColor     
      case DOTS_COLOR:
        setDotsColor(value);
        data.dotsOptions.color = dotsColor;
        break;

      // sets QR Code cornerSquareColor   
      case CORNERSQUARE_COLOR:
        setCornerSquareColor(value);
        data.cornersSquareOptions.color = cornerSquareColor;
        break;

      // sets QR Code cornerDotColor  
      case CORNERDOTS_COLOR:
        setCornerDotColor(value);
        data.cornersDotOptions.color = cornerDotColor;
        break;

      // sets QR Code background color  
      case BACKGROUND_COLOR:
        setBackground(value);
        data.backgroundOptions.color = background;
        break;
      default: 
        
    }
  }
  const handleClick = () => {
    if (status===false) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  return (
    // customisation option container
    <div className={styles.custom_input_container} data-testid="Custom_Render">
      {/* customisation field above line */}
      <hr className={styles.border}/>
      {/* custome otion button on click handleclick fucnction */}
      <p className={styles.option_drop_container} onClick={handleClick} data-testid="custom_hide">
        Optional Customisations{" "}
        <span>
          {/* down arrow icon */}
          <img src={downicon} alt="" />
        </span>
      </p>
      {/* button status if status true then form should be render on screen */}
      {status ? (
        <>
          <div>
            {/* size input filed box */}
            <p className={styles.size_title} data-testid="heading">Size Options</p>
            <p className={styles.size_sub_title}>Change the main dots</p>
          </div>
          <div className={styles.size_container}>
            <div className={styles.size_width}>
              {/* width input */}
              <input 
                onChange={handleChange} 
                name="width" 
                value={qrWidth} 
                data-testid="width-1" 
                className={styles.width_input} 
                placeholder="Width" 
                type="number" 
              />
              <img className={styles.width__icon} src={widthicon} alt="" />
            </div>
            <div className={styles.size_height}>
              {/* height input */}
              <input 
                onChange={handleChange} 
                name="height" 
                value={qrHeight} 
                className={styles.height_input} 
                placeholder="Height" 
                type="number" 
                data-testid="height"
              />
              <img className={styles.height__icon} src={heighticon} alt=""/>
            </div>
          </div>
          <div className={styles.margin_box}>
            {/* margin input */}
            <input 
              onChange={handleChange} 
              name="margin" 
              value={qrMargin} 
              className={styles.margin_input} 
              placeholder="Margin" 
              type="number"  
              data-testid="margin"
            />
            {/* margin icon element */}
            <img className={styles.margin__icon} src={margin} alt="" />
          </div>
          {/* drop down option container */}
          <div>
            <p className={styles.size_title}>Dots Options</p>
            <p className={styles.size_sub_title}>Customise the QR Dots & Squares</p>
          </div>
          {/* dot style select dropdown */}
          <div className={styles.dot_select_container}>
            <select onChange={handleChange} name="dots-style" value={dotsStyle} data-testid="Dot-style">
              <option  disabled >Dots Styles</option>
              <option value="square">Square</option>
              <option value="dots">Dots</option>
              <option value="rounded">Rounded</option>
              <option value="extra-rounded">Extra rounded</option>
              <option value="classy">Classy</option>
              <option value="classy-rounded">Classy rounded</option>
            </select>
          </div>
          {/* Corner square style dropdown option */}
          <div className={styles.sqaure_select_container}>
            <select onChange={handleChange} name="corner-square" value={cornerSquareOption} data-testid="square-style">
              <option  disabled hidden>Corner Square Styles</option>
              <option value="square">Square</option>
              <option value="dots">Dots</option>
              <option value="extra-rounded">Extra rounded</option>
            </select>
          </div>
          {/* Corne Dot style drop down options*/}
          <div className={styles.corner_select_container}>
            <select onChange={handleChange} name="corner-dots" value={cornerDotOption} data-testid="corner-style">
              <option  disabled hidden>Corner Dots Styles</option>
              <option value="">None</option>
              <option value="square">Square</option>
              <option value="dots">Dots</option>
            </select>
          </div>
          {/* colour Picker container  */}
          <div>
            <p className={styles.size_title}>Colours</p>
            <p className={styles.size_sub_title}>
              Customise the QR Dots & Squares colours
            </p>
          </div>
          {/* dot Color picker */}
          <div className={styles.color_picker_box}>
            <p>Dots Colour: </p>
            {/* color picker input field */}
            <input 
              onChange={handleChange} 
              name="dots-color" 
              type="color" 
              value={dotsColor} 
            />
          </div>
          {/* corner square colour picker */}
          <div className={styles.color_picker_box}>
            <p>Corner Square Colour: </p>
            <input 
              onChange={handleChange} 
              name="cornersquare-color" 
              type="color" 
              value={cornerSquareColor}
            />
          </div>
          {/* corner dot colour picker */}
          <div className={styles.color_picker_box}>
            <p>Corner Dots colour:: </p>
            <input 
              onChange={handleChange} 
              name="cornerdots-color" 
              type="color" 
              value={cornerDotColor}
            />
          </div>
          {/* background clour picker */}
          <div className={styles.color_picker_box}>
            <p>Background Colour: </p>
            <input 
              onChange={handleChange} 
              name="background-color" 
              type="color" 
              value={background} 
            />
          </div>
        </>
      ) : (
        // empty div render while status is false
        <div className={styles.emptydiv}></div>
      )}
    </div>
  );
};
export default CustomInput;
