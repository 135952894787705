import React from "react";
import styles from "../styles/Navbar.module.css";
import Logo from "../assets/images/AVM Logo Black 1.svg";

const Navbar = () => {

  return (
    <div className={styles.navbar_container} data-testid="navbar">
      {/* navbar logo container */}
      <div className={styles.left_logo_container}>
        {/* Logo */}
        <img className={styles.logo__img} src={Logo} alt="" role="img"/>
      </div>
      {/* center heading  */}
      <div className={styles.title__container}>
        <p>QR Code Generator</p>
      </div>
      {/* right external link buttons */}
      <div className={styles.right_btn_container}>
        <a href="https://avmsolutionsuk.com" id="go-to-website" style={{textDecoration: 'none'}}>
          <button className={styles.button_external}  data-testid="btn1">Go to Website</button>
        </a>
        <a href="https://erp.avmsolutions.co.uk/" id="go-to-ERP" style={{textDecoration: 'none'}}>
          <button
            className={`${styles.button_external} ${styles.next_button_external}`} data-testid="btn2">
            Go to ERP
          </button>
        </a>
      </div>
    </div>
  );
};

export default Navbar;
