import "./App.css";
import QRCodeStyling from 'qr-code-styling';
import Navbar from "./Components/Navbar";
import QrCode from "./Components/QrCode";
import QrForm from "./Components/QrForm";
import { data } from './data/config';

const qrCode = new QRCodeStyling(data);

function App() {

  return (
    <div className="App" data-testid="app">
      
      <Navbar />
      <div className="box">
        <QrForm qrCode={qrCode}/>
        <QrCode qrCode={qrCode} />
      </div>
    </div>
  );
}

export default App;
