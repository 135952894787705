import React, { useEffect, useRef, useState } from "react";
import { onDownloadClick } from '../services/downloadService';
import solid from "../assets/images/Solid.svg";
import styles from "../styles/QrCode.module.css";

const QrCode = ({qrCode}) => {
  const ref = useRef(null);
  const [fileExt, setFileExt] = useState("png");

  useEffect(() => {
    // qrCode.append: appends qrcode onto the canvas
    qrCode.append(ref.current);
  },[qrCode]);

  /**
   * Handles change events and sets File Extension
   * @param {e} event - changeEvent
   * @return {void}
   * 
  */
  const onExtensionChange = (e) => {
    setFileExt(e.target.value);
  };

  return (
    // Qr code container
    <div className={styles.qr_container}>
      {/* qr image parent container */}
      <div className={styles.qr_parent_box}>
        <div className={styles.qr_sub_box}>
          {/* Qr image render on screen box */}
          <div className={styles.qr_img} ref={ref}></div>
        </div>
      </div>
      {/* export data container */}
      <div className={styles.export_container}>
        <p className={styles.export_title}>Export as:</p>
        {/* export type select dropdown */}
        <div className={styles.export_select_container}>
          <select className={styles.export_option_selector} onChange={onExtensionChange} value={fileExt}> 
            <option  disabled hidden>.JPEG, .PNG , .SVG</option>
            <option value="png">.PNG</option>
            <option value="jpeg">.JPEG</option>
            <option value="svg">.SVG</option>
          </select>
          {/* export icon */}
          <img className={styles.export_icon} src={solid} alt="expoimag" /> 
        </div>
      </div>
      {/* export button */}
      <button onClick={() => onDownloadClick(fileExt,qrCode)} className={styles.export_btn} >Export QR Code</button>
    </div>
  );
};

export default QrCode;
